<template>


        <div class="">
            <div class="admin-view-toolbar " style="margin-bottom: 40px;">

                <div class="uk-flex uk-flex-between">
                    <div class="start-side">
                        <router-link :to="createRoute"
                                     v-if="allowCreate"
                                      custom
                                      v-slot="{ navigate, href, route }">
                            <form-button @click="navigate"
                                         class="uk-margin-small-right"
                                         icon="plus"
                                         :showSlot="false"
                                         :uk-tooltip="translateTitleCase('core.crud.newTooltip')"
                                         type="primary">
                            </form-button>
                        </router-link>
                        <form-button @click="deleteCurrent"
                                     class=""
                                     icon="trash"
                                     type="danger"
                                     :showSlot="false"
                                     :uk-tooltip="translateTitleCase('core.crud.deleteSelectedTooltip')"
                                     v-if=" areItemsSelected && allowDelete ">
                        </form-button>
                    </div>
                    <div class="end-side">
                        <form-button
                            icon="close"
                            type="default"
                            :uk-tooltip="translateTitleCase('core.crud.clearFiltersTooltip')"
                            class="uk-button-danger uk-animation-slide-bottom-small uk-animation-fast"
                            v-if="hasFilters"

                            @click.prevent="clearFilters()"
                        >{{translateUcFirst('core.crud.clearFilters')}}</form-button>
                        <form-button
                                icon="search"
                                type="default"
                                :uk-tooltip="translateTitleCase('core.crud.filterTooltip')"
                                :class="{'uk-button-primary' : showFiltersLocal} "
                                @click.prevent="showFiltersLocal = ! showFiltersLocal "
                        >{{translateUcFirst('core.crud.filter')}}</form-button>
                    </div>
                </div>

            </div>
            <block  class="filters uk-flex uk-flex-start uk-flex-wrap uk-grid-small uk-margin uk-animation-fade uk-animation-fast"
                   v-show="finalShowFilters"
                   v-if="listData.form">

                <form-input v-for="(conf, key) of filters" :key="key" :conf="conf"
                            :label="conf.label"
                            :name="key"
                            v-bind="conf"
                            :wrapperMargins="false"
                            v-model="itemFilters[key]"
                            class="uk-width-1-1 uk-width-1-2@s uk-width-1-3@m uk-width-1-4@l"
                ></form-input>
            </block>

            <Skeleton :template="'lines'"
                      :count="10"
                      :fitHeight="false"
                      :defaultHeight="500"
                      :contentReady=" ! asyncOps.asyncStatus.asyncDataLoading">
                <h4 v-if="itemsData.length < 1" >
                    {{translateSafe('core.crud.noItemsFound')}}
                </h4>

                <block class="items-table uk-overflow-auto" v-if="itemsData.length > 0">
                    <table class="uk-table uk-table-striped uk-table-divider ">
                        <thead>
                        <tr>
                            <th class="list-column-header list-column-header--control uk-table-shrink uk-table-middle uk-text-center"
                                v-if="columnData.meta.hasSelect">
                                <inline>
                                    <form-input
                                            :wrapperMargins="false"
                                            type="checkbox"
                                            @change="toggleSelectAllEntities"/>
                                </inline>
                            </th>
                            <th class="list-column-header list-column-header--ordering uk-table-shrink uk-table-middle uk-text-center"
                                v-if="columnData.meta.hasOrdering"
                                @click="setSorting('order')">
                                {{translateSafe('core.crud.ordering')}}
                                <inline class="col-sort-indicator" v-if="itemSorting.key == 'order' && itemSorting.direction === 'asc'" uk-icon="triangle-up"></inline>
                                <inline class="col-sort-indicator" v-if="itemSorting.key == 'order' && itemSorting.direction === 'desc'" uk-icon="triangle-down"></inline>
                            </th>
                            <th class="list-column-header list-column-header--state uk-table-shrink uk-table-middle uk-text-center"
                                v-if="columnData.meta.hasState"
                                @click="setSorting('state')">
                                {{translateSafe('core.crud.state')}}
                                <inline class="col-sort-indicator" v-if="itemSorting.key == 'state' && itemSorting.direction === 'asc'" uk-icon="triangle-up"></inline>
                                <inline class="col-sort-indicator" v-if="itemSorting.key == 'state' && itemSorting.direction === 'desc'" uk-icon="triangle-down"></inline>

                            </th>
                            <th class="list-column-header list-column-header--id uk-table-shrink uk-table-middle uk-text-center"
                                @click="setSorting('id')"
                                v-if="columnData.meta.hasId">
                                {{translateSafe('core.crud.id')}}
                                <inline class="col-sort-indicator" v-if="itemSorting.key == 'id' && itemSorting.direction === 'asc'" uk-icon="triangle-up"></inline>
                                <inline class="col-sort-indicator" v-if="itemSorting.key == 'id' && itemSorting.direction === 'desc'" uk-icon="triangle-down"></inline>

                            </th>

                            <clean-wrapper v-for="(column, fieldName) of columnData.columns"
                                           :key="fieldName">
                                <th class="list-column-header generic-column-header"
                                        v-if="column.showList"
                                    :class="['list-column-header-'+fieldName]"
                                    @click="setSorting(fieldName)">
                                    <inline class="col-label">{{translateUcFirst(column.label)}}</inline>
                                    <inline class="col-sort-indicator" v-if="itemSorting.key == fieldName && itemSorting.direction === 'asc'" uk-icon="triangle-up"></inline>
                                    <inline class="col-sort-indicator" v-if="itemSorting.key == fieldName && itemSorting.direction === 'desc'" uk-icon="triangle-down"></inline>
                                </th>

                            </clean-wrapper>


                        </tr>
                        </thead>
<!-- TODO: only allow this sortable when list is sorted by order ASC -->
                        <tbody v-sortable="{onUpdate:onReorder, handle: '[data-role=handle]'}">

                            <tr  class="uk-animation-fade uk-animation-fast"
                             v-for="item of itemsData"
                             :item="item ">
                            <!-- select cell -->
                            <th class="list-table-cell list-table-cell--control uk-table-shrink uk-table-middle uk-text-center"
                                v-if="columnData.meta.hasSelect">
                                <form-input
                                        :wrapperMargins="false"
                                        type="checkbox"
                                        v-model="selectedEntities[item.id]"/>
                            </th>

                            <!-- ordering cell -->
                            <td class="list-table-cell list-table-cell--ordering uk-table-shrink uk-table-middle uk-text-center"
                                v-if="columnData.meta.hasOrdering">
                                <inline uk-icon="menu"
                                        data-role="handle"
                                        style="color: var(--lightbox-background); cursor: move;"
                                        v-if="itemSorting.key == 'order' && itemSorting.direction === 'asc'"
                                ></inline>
                                <inline uk-icon="menu"
                                        style="color: var(--global-muted-color);cursor: not-allowed"
                                        :uk-tooltip="translate('core.crud.sortReorderBlockedTooltip')"
                                        v-else
                                ></inline>
                            </td>

                            <!-- state cell -->
                            <td class="list-table-cell list-table-cell--state uk-table-shrink uk-table-middle uk-text-center"
                                v-if="columnData.meta.hasState">
                                <block v-if="loadingEntities[item.id]">
                                    <spinner :size="0.7" class="uk-flex uk-flex-center"></spinner>
                                </block>
                                <block v-else="loadingEntities[item.id]">
                                    <inline uk-icon="check"
                                            class="uk-text-success"
                                            v-if="item.state == '1'"
                                            @click="setItemState(item.id, 0)"></inline>
                                    <inline uk-icon="close"
                                            class="uk-text-danger"
                                            @click="setItemState(item.id, 1)"
                                            v-else></inline>
                                </block>

                            </td>

                            <!-- id cell -->
                            <td class="uk-table-link uk-table-middle uk-text-center"
                                v-if="columnData.meta.hasId">
                                <router-link :to="getItemRouteConfig(item.id, item)">{{item.id}}</router-link>
                            </td>
                            <clean-wrapper v-for="(column, key) of columnData.columns"
                                           :key="key">
                                <td class="uk-text-truncate">
                                    <router-link :to="getItemRouteConfig(item.id, item)" v-if="columnData.columns[key].isPrimary">
                                        {{getDisplayString(item, key)}}
                                    </router-link>
                                    <inline v-else>
                                        {{getDisplayString(item, key)}}
                                    </inline>
                                </td>
                            </clean-wrapper>

                        </tr>

                        </tbody>
                    </table>
                </block>

                <pagination
                        class="uk-margin-top"
                        v-bind="paginationData"
                        v-model:currentPage="itemPagination.start"
                ></pagination>
                <div class="uk-flex uk-flex-center uk-width-expand">
                    <form-input name="paginationSize"
                                :inputSize="100"
                                type="select"
                                icon="list"
                                :list="[
                                {value: 5, label: '5'},
                                {value: 10, label: '10'},
                                {value: 20, label: '20'},
                                {value: 50, label: '50'},
                                {value: 100, label: '100'},
                            ]"
                                :autoTranslate="false"
                                :nullOption="{disabled: true, label: 'core.crud.selectPaginationSize'}"
                                :uk-tooltip="translateTitleCase('core.crud.changePaginationSizeTooltip')"
                                :wrapperMargins="false"
                                v-model.number="itemPagination.limit"></form-input>
                </div>

            </Skeleton>

        </div>


</template>

<script>
    // TODO: have the meta fields dynamic, allowing parent to determine if they exist
    import { useStore } from 'vuex'

    import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';
    //    import paginationComposition  from '@/client/extensions/composition/pagination.js';
    import { computed, reactive, watchEffect } from 'vue';

    export default {
        name: 'crud-list',
        props: {
            /**
             * Name for this list. to be used as identifier for store and such
             **/
            listName : {
                type: [String, Boolean],
                default: false,
            },
            /**
             * Url to fetch assets from
             */
            apiGetUrl: {
                type: String,
                default: false
            },
            /**
             * Url tp update assets to. String lets us build a restful route with it as a prefix,
             * method will receive arguments and should return the URL
             */
            apiUpdateUrl: {
                type: [String, Function],
                default: false
            },
            /**
             * Url tp delete assets. String lets us build a restful route with it as a prefix,
             * method will receive arguments and should return the URL
             */
            apiDeleteUrl: {
                type: [String, Function, Boolean],
                default: false
            },
            /**
             * Url to order assets. String lets us build a restful route with it as a prefix,
             * method will receive arguments and should return the URL
             */
            apiOrderUrl: {
                type: [String, Function, Boolean],
                default: false
            },
            /**
             * Route configuration to create a new item
             */
            createRoute : {
                type: [Object, Boolean],
                default: false,
            },
            /**
             * Configuration to go to edit a specific item using router.
             * String will be used as a route name, and the item id will be passed as a parameter
             * Function will recieve id and return the router config object
             */
            editRoute : {
                type: [String, Function],
                default: false
            },
            /**
             * Used to force this component to show it's filters
             */
            showFilters: {
                type: Boolean,
                default: false,
            },
            /**
             * Toggle rendering of the Select column (checkboxes)
             */
            showSelectColumn: {
                type: Boolean,
                default: true
            },
            /**
             * Toggle rendering of the Ordering column (drag and drop orderong)
             */
            showOrderingColumn: {
                type: Boolean,
                default: true
            },
            /**
             * Toggle rendering of the State column (publish/unpublish)
             */
            showStateColumn: {
                type: Boolean,
                default: true
            },
            /**
             * Toggle rendering of the ID column (show friendly ids)
             */
            showIdColumn: {
                type: Boolean,
                default: true
            },
            allowDelete: {
                type: Boolean,
                default: true,
            },
            allowCreate: {
                type: Boolean,
                default: true,
            },

        },

        setup (props, setupContext ) {
            let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());

            return {asyncOps, asyncOpsReady, asyncStatus,}
        },
        data: function () {
            let listIdentifier;

            return {
                cookieRules : { path: ''},
                allEntitiesSelected: false,
                loadingEntities: {},
                showFiltersLocal: false,
                selectedEntities: {

                },

                // we have a this.listName which is a string or false.
                // these - we want from store
                itemPagination: {
                    start: 0,
                    limit: 10
                },
                itemFilters: {

                },
                itemSorting: {
                    key: 'id',
                    direction: 'desc',
                },
                // end

                listData : {},
                asyncData : {
                    listData: {
                        target : computed(() => (this.apiGetUrl)),
                        data : computed(() => {
                            return {
                                pagination : this.itemPagination,
                                filters : this.itemFilters,
                                ordering: this.itemSorting
                            }
                        }),
                        options : {
                            method: 'get',
                        },
                    },
                },
            };
        },
        computed : {
            filters () {
                if ( ! this.listData.form) {
                    return {};
                }

                let fields = Object.assign(this.listData.form.fields);
                let result = {};

                for (const [key, field] of Object.entries(fields)) {
                    let filterable = field.filterable || {};

                    if (filterable) {
                        result[key] = {
                            label:  field.label,
                            type: 'text',
                            placeholder: filterable.placeholder ?? '',
                            ...filterable
                        };
                    }
                }

                return result;
            },
            hasFilters () {
                return Object.entries({...this.itemFilters}).length !== 0;
            },
            selectedItems () {
                let result = [];
                for (const [id, isSelected] of Object.entries(this.selectedEntities)) {
                    if (isSelected) {
                        result.push(id);
                    }
                }
                return result;
            },
            areItemsSelected () {
                return this.selectedItems.length > 0;
            },
            paginationData () {
                return {
                    'totalCount' : this.listData.totalCount,
                    'currentPage': this.itemPagination.start,
                    'pageSize' : this.itemPagination.limit,
                }
            },
            columnData() {
                let meta = {
                    hasSelect: this.showSelectColumn,
                    hasOrdering: this.showOrderingColumn,
                    hasState: this.showStateColumn,
                    hasId: this.showIdColumn
                };

                let formFields = this.listData?.form?.fields || {};
                let columns = {};
                Object.entries(formFields).map((entry) => {
                    let field = entry[1];
                    let showList = (field?.itemListOptions?.show) || false;

                    if ( ! showList) {
                        return true;
                    }

                    columns[field.name] = {
                        name        : field.name,
                        label       : field.label,
                        showList    : field?.itemListOptions?.show || false,
                        isPrimary   : field?.isPrimaryField || false,
                        type        : field?.type || 'text',
                        multiple    : field?.multiple || false,
                        source      : field?.source   || null,
                        sourceKey   : field?.sourceKey || null,
                        relatedPrimaryField: field?.relatedPrimaryField || null
                    }
                });


                return {meta, columns};

            },
            itemsData() {
                let items = [...(this.listData.items || [])];

                return items;
            },
            finalShowFilters () {
                return this.showFilters || this.showFiltersLocal;
            },

        },
        methods: {
            getItemRouteConfig (id, item) {
                if (typeof this.editRoute === 'string') {
                    return {name: this.editRoute, params: {id: id}}
                } else {
                    return this.editRoute(id, item);
                }

            },
            updateItemLocal (id, payload = {}) {
                for (const [index, item] of Object.entries(this.listData.items)) {
                    if (item.id == id) {
                        this.listData.items[index] = {...this.listData.items[index], ...payload}
                    }
                }
            },
            clearSelectedEntities() {
                this.allEntitiesSelected = false;
                this.selectedEntities = reactive({});
            },
            clearLoadingEntities() {
                this.loadingEntities = reactive({});
            },
            setItemState : async function (id, value) {
                let url;

                // set loading
                this.loadingEntities[id] = true;

                // make value safe just in case
                value = parseInt(value);

                let payload = {state: value};

                // calculate the url - we are either provided with path prefix (for REST) or a function
                if (typeof this.apiUpdateUrl === 'function') {
                     url     = this.apiUpdateUrl(id);
                } else {
                     url     = `${this.apiUpdateUrl}/${id}`;
                }

                let result  = await this.asyncOps.asyncCall(url, payload, {method: 'patch'});

                if ( result.isError) {
                    this.$saffron.ui.notification('core.errorOccurredGeneric','error');
                    return false;
                }

                this.updateItemLocal(id, payload);

                setTimeout(() => {
                    this.loadingEntities[id] = false;
                }, 200);
            },
            deleteCurrent : async function () {
                // confirm
                try {
                    await this.$saffron.ui.modal.confirm(this.translateUcFirst('core.crud.deleteConfirmText'));
                } catch (e) {
                    this.$saffron.ui.notification(this.translateUcFirst('core.actionCanceled'));
                    return;
                }


                let route;
                let payload = {ids: Object.keys(this.selectedEntities)};

                if ( ! this.apiDeleteUrl) {
                    utilities.debug('crud delete error - can not delete apiDeleteUrl property was not defined', 2, this);
                }
                if (typeof this.apiDeleteUrl === 'function') {
                    route =  this.apiDeleteUrl(payload);
                } else {
                    route =  `${this.apiDeleteUrl}`;
                }


                for (const [key, value] of Object.keys(this.selectedEntities)) {
                    this.loadingEntities[key] = true;
                }

                let result = await this.asyncOps.asyncCall(route, payload, {method: 'delete'});

                if ( result.isError) {
                    this.$saffron.ui.notification(this.translateUcFirst('core.errorOccurredGeneric'));
                }

                // clear select/loading states
                this.clearLoadingEntities();
                this.clearSelectedEntities();

                // refresh list
                this.refreshAsyncData();
            },
            getOppositeSortingDirection (dir) {
                if (dir === 'asc') {
                    return 'desc';
                } else {
                    return 'asc';
                }
            },
            selectAllEntities() {
                for (const [key, item] of Object.entries(this.listData.items)) {
                    this.selectedEntities[item.id] = true;
                }
            },
            toggleSelectAllEntities () {
                if (this.allEntitiesSelected) {
                    this.clearSelectedEntities();
                    this.allEntitiesSelected = false;
                } else {
                    this.selectAllEntities();
                    this.allEntitiesSelected = true;
                }
            },
            setSorting (col) {
                // if we are already on this column, invert the order
                if ( this.itemSorting.key === col) {
                    this.itemSorting.direction = this.getOppositeSortingDirection(this.itemSorting.direction);
                    return;
                }

                // we are sorted on another column, change that
                this.itemSorting.key = col;
            },
            getDisplayString (item, key) {
                let colData = this.columnData.columns[key];

                // generic display where there is not configuration for the column
                if ( ! colData) {
                    return item[key];
                }

                // single relation display
                if (colData.type === 'entityRelation' && ! colData.multiple && colData.relatedPrimaryField) {
                    let target = item[colData.source];

                    if (typeof item[colData.source] === 'string') {
                        target = JSON.parse(item[colData.source]);
                    }

                    if ( target) {
                        return target[colData.relatedPrimaryField];
                    }

                    return '-';
                }

                // multi relation display
                if (colData.type === 'entityRelation' && colData.multiple && colData.relatedPrimaryField) {
                    let target;
                    if ( ! colData.source) {
                        return 'view item to see relation';
                    }

                    target = item[colData.source];
                    if ( ! Array.isArray(target) || target.length < 1) {
                        return '-'
                    }

                    return target.map(item => {
                        return item[colData.sourceKey]
                    }).join(', ');
                }

                // generic display
                return item[key];
            },
            async onReorder (e) {
                let list, data = {} ,reqData, stop;
                if ( ! this.apiOrderUrl ) {
                    return false;
                }
//notice: we are reloading state from server and working really hard, becuase aparently vue has a bug in reordering array + rendering it
                function move(input, from, to) {
                    let numberOfDeletedElm = 1;

                    const elm = input.splice(from, numberOfDeletedElm)[0];

                    numberOfDeletedElm = 0;

                    input.splice(to, numberOfDeletedElm, elm);
                }
                list = [...this.listData.items];
                move(list,e.oldIndex, e.newIndex);
                list.map((item, index) => {
                    data[index] = item.id;
                });
                reqData = {newOrdering:data, entityType:this.entityType};

                this.$store.commit('ui/showGlobalSpinner', '');
                let result = await this.asyncOps.asyncCall(this.apiOrderUrl, reqData, {method: 'patch'});
                if ( result.isError) {
                    this.$saffron.ui.notification(this.translate('core.errorOccurredGeneric'), 'error');
                }
                this.refreshAsyncData()
                stop = watchEffect(async() => {
                    if (this.asyncOps.asyncStatus.asyncDataLoading === false) {
                        stop();
                        utilities.wait(250).then(() => {
                            this.$store.commit('ui/hideGlobalSpinner');
                        });
                    }
                })

            },
            getListIdentifier() {
                if (typeof this.listName === 'string') {
                    return this.listName;
                } else if ( ! this.apiGetUrl) {
                    return 'list-generic';
                } else {
                    return 'list-'+this.apiGetUrl.replaceAll('/', '_');
                }
            },
            getCookieName() {
                return this.getListIdentifier() + '_state';
            },
            lazySetCookie() {
                try {
                    let cookieName =  this.getCookieName();

                    let val = this.$cookie.get(cookieName);

                    if ( ! val) {
                        this.$cookie.set(cookieName, {
                            itemPagination: this.itemPagination,
                            itemFilters: this.itemFilters,
                            itemSorting: this.itemSorting,
                        }, this.cookieRules);
                    }
                } catch (e) {

                }

            },
            setCookieState (key, val) {
                try {
                    this.lazySetCookie();
                    let cookie = this.$cookie.get(this.getCookieName());
                    cookie[key] = val;
                    this.$cookie.set(this.getCookieName(), cookie, this.cookieRules);
                } catch (e){

                }
            },
            loadStateFromCookie () {
                let cookie;

                try {
                    cookie = this.$cookie.get(this.getCookieName());
                } catch (e) {
                    return;
                }

                if ( ! cookie || typeof cookie !== 'object') {
                    return;
                }

                // assign cookie values
                if (typeof cookie.itemSorting === 'object'  && cookie.itemSorting) {
                    this.itemSorting = cookie.itemSorting;
                }

                if (typeof cookie.itemPagination === 'object'  && cookie.itemPagination) {
                    this.itemPagination = cookie.itemPagination;
                }

                if (typeof cookie.itemFilters === 'object'  && cookie.itemFilters) {
                    this.itemFilters = cookie.itemFilters;
                }
            },
            clearFilters() {
                this.itemFilters = {};
            }
        },
        watch : {
            listData () {
                this.clearLoadingEntities();
            },
            showFilters(newVal) {
                this.showFiltersLocal = newVal;
            },
            itemSorting : {
                handler (newVal, oldVal) {
                    this.setCookieState('itemSorting', newVal);
                },
                deep: true
            },
            itemPagination : {
                handler (newVal, oldVal) {
                    this.setCookieState('itemPagination', newVal);
                },
                deep: true
            },
            itemFilters : {
                handler (newVal, oldVal) {
                  // do not allow seaching for null. if a filter field is empty, it's value may be null.
                    // but the user is not looking for "null" - they are just not looking for anything, not filtering.
                   for (const [index, value] of Object.entries(newVal)) {
                       if (value === null) {
                           delete newVal[index];
                       }
                   }
                    this.setCookieState('itemFilters', newVal);
                },
                deep: true
            }
        },
        mounted() {
            this.loadStateFromCookie();
        },

    }
</script>

<style scoped lang="scss">
    .meta-column-header{
        white-space: nowrap;
    }

    .list-column-header {
        position: relative;
        cursor: pointer;

        .col-sort-indicator {
            position: absolute;
            top: calc(50% + 1px);
            left: -5px;
            transform: translateY(-50%);
            z-index: 5;
            display: inline-block;
        }
    }

    .filters {
        position: relative;
        z-index: 10;
    }

    .generic-column-header{

    }
</style>
